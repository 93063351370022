import { formatDate } from '../utils/helper';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { getUser, getLogsForUser } from '../api/urls.js';
import { FaArrowLeft } from 'react-icons/fa';
import useAxiosPrivate from '../hooks/useAxiosPrivate.js';

function User() {
	const [userInfo, setUserInfo] = useState(null);
	const [logs, setLogs] = useState([]);
	const navigate = useNavigate();
	const { userId } = useParams();
	const axiosPrivate = useAxiosPrivate();

	useEffect(() => {
		// get data on user including recent logs
		async function fetchData() {
			try {
				const responseUser = await axiosPrivate.get(getUser(userId));
				setUserInfo(responseUser.data);

				const responseLogs = await axiosPrivate.get(getLogsForUser(userId));
				setLogs(responseLogs.data);
			} catch (error) {
				// handle error
			}
		}

		fetchData();
	}, [axiosPrivate, userId]);

	return (
		<main>
			<div className='top'>
				<h2>Employee Details</h2>
				<button
					className='btn back-btn'
					onClick={() => navigate(-1)}
				>
					<FaArrowLeft />
					Go Back
				</button>
			</div>

			<hr />
			{/* user info */}
			{userInfo ? (
				<>
					<div className='info-group'>
						<h4>Name</h4>
						<p>
							{userInfo.first_name} {userInfo.last_name}
						</p>
					</div>

					<div className='info-group'>
						<h4>Employee ID</h4>
						<p>{userInfo.employee_id}</p>
					</div>

					<div className='info-group'>
						<h4>Company</h4>
						<p>{userInfo.company}</p>
					</div>

					<div className='info-group'>
						<h4>Mobile</h4>
						<p>{userInfo.mobile}</p>
					</div>

					<div className='info-group'>
						<h4>MAC Address</h4>
						<p>{userInfo.mac_address}</p>
					</div>

					{/* recent logs */}
					<h3 className='recent-logs-heading'>Recent Connection History</h3>
					<hr />
					<table className='recent-logs-table'>
						<thead>
							<tr>
								<th>#</th>
								<th>Location</th>
								<th>Date & Time</th>
								<th>Type</th>
							</tr>
						</thead>
						<tbody>
							{logs.length > 0 ? (
								logs.map((log) => (
									<tr key={log.id}>
										<td>{log.id}</td>
										<td>{log.location_name}</td>
										<td>{formatDate(log.time)}</td>
										<td className={log.type === 'arrive' ? 'green' : 'red'}>
											{log.type}
										</td>
									</tr>
								))
							) : (
								<tr>
									<td
										className='no-logs'
										colSpan='5'
									>
										No Recent Logs to Display
									</td>
								</tr>
							)}
						</tbody>
					</table>
				</>
			) : (
				<p>No data found for this user.</p>
			)}
		</main>
	);
}

export default User;
