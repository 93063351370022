import { useState, useContext } from 'react';
import axios from '../api/axios';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../context/userContext.js';
import { jwtDecode } from 'jwt-decode';

function Login() {
	const { setUser } = useContext(UserContext);
	const [formData, setFormData] = useState({
		username: '',
		password: '',
	});
	const [errorMsg, setErrorMsg] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const navigate = useNavigate();

	function handleChange(event) {
		const { name, value } = event.target;
		setFormData({
			...formData,
			[name]: value,
		});
		//clear error
		setErrorMsg('');
	}

	async function handleSubmit(event) {
		event.preventDefault(); // stop browser page reload
		setIsLoading(true);
		setErrorMsg('');
		// validate not blank
		if (formData.username === '' || formData.password === '') {
			setErrorMsg('Cannot leave password and/or username blank.');
			setIsLoading(false);
			return;
		}
		// authenticate user
		const body = {
			username: formData.username,
			password: formData.password,
		};
		try {
			const response = await axios.post('/admin/authenticate', body);
			const data = response.data;
			const token = data.accessToken;
			// set user for user context
			const decoded = jwtDecode(token);
			setUser({ ...decoded, accessToken: token });
			navigate('/'); // redirect to homepage
		} catch (error) {
			if (error?.response?.statusText === 'Unauthorized') {
				setErrorMsg("'Username or password is incorrect.");
			} else {
				setErrorMsg(error.message);
			}
		} finally {
			setIsLoading(false);
		}
	}

	return (
		<>
			<form
				className='login-form'
				onSubmit={handleSubmit}
			>
				<h2>Login</h2>
				{/* username */}
				<label>
					Username{' '}
					<input
						name='username'
						type='text'
						placeholder='username'
						onChange={handleChange}
						value={formData.username}
					/>
				</label>

				{/* password */}
				<label>
					Password{' '}
					<input
						name='password'
						type='password'
						placeholder='**********'
						onChange={handleChange}
						value={formData.password}
					/>
				</label>

				{/* submit button */}
				<button
					className={isLoading ? 'btn btn-verifying' : 'btn btn-login'}
					type='submit'
				>
					{!isLoading ? <>Login</> : <> Verifying...</>}
				</button>

				{/* feedback message */}
				<p className='error'>{errorMsg}</p>
			</form>
		</>
	);
}

export default Login;
