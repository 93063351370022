import { NavLink } from 'react-router-dom';
import { FiLogIn } from 'react-icons/fi';
import { UserContext } from '../context/userContext.js';
import { useContext, useEffect, useState } from 'react';
import useLogout from '../hooks/useLogout.js';
import useAxiosPrivate from '../hooks/useAxiosPrivate.js';
import logo from '../assets/images/logo.png';

function Header() {
	const { user } = useContext(UserContext);
	const logout = useLogout();
	const axiosPrivate = useAxiosPrivate();
	const [companyName, setCompanyName] = useState('');

	useEffect(() => {
		if (user) {
			async function fetchData() {
				// get company name
				try {
					const response = await axiosPrivate.get('/companies/name');
					setCompanyName(response.data);
				} catch (error) {
					// handle error
				}
			}

			fetchData();
		}
	}, [user, axiosPrivate]);

	return (
		<div className='header'>
			<div className='header-row'>
				{/* Logo */}
				<NavLink
					className='link'
					to='/'
				>
					<img
						src={logo}
						alt='safe site connect logo'
						width={100}
					/>
				</NavLink>
				{/* Navbar */}
				<ul className='navbar'>
					<li>
						<NavLink
							className='link'
							to='/'
						>
							Dashboard
						</NavLink>
					</li>
					<li>
						<NavLink
							className='link'
							to='/onsite'
						>
							On-site
						</NavLink>
					</li>
					<li>
						<NavLink
							className='link'
							to='/logs'
						>
							Logs
						</NavLink>
					</li>
					<li>
						<NavLink
							className='link'
							to='/incidents'
						>
							Incidents
						</NavLink>
					</li>
					<li>
						<NavLink
							className='link'
							to='/alert'
						>
							Alerts
						</NavLink>
					</li>
					<li>
						<NavLink
							className='link'
							to='/config'
						>
							Config
						</NavLink>
					</li>
				</ul>

				{/* if user logged in show logout else log in*/}
				{user ? (
					<>
						<p className='company-name'>{companyName}</p>
						<li className='logout-link'>
							<div>
								<p
									className='logout'
									onClick={logout}
								>
									Logout
								</p>
							</div>
						</li>
					</>
				) : (
					<li className='logout-link'>
						<NavLink
							className='link'
							to='/login'
						>
							<FiLogIn className='login-icon' />
							Login
						</NavLink>
					</li>
				)}
			</div>
		</div>
	);
}

export default Header;
