import { Outlet } from 'react-router-dom';
import { useState, useEffect, useContext } from 'react';
import useNewAccessToken from '../hooks/useNewAccessToken';
import userContext from '../context/userContext';

const PersistLogin = () => {
	/* 
    This fixs issue that refreshing page breaks everything.
    Gets new access token after refresh 
    */
	const [isLoading, setIsLoading] = useState(true);
	const refresh = useNewAccessToken();
	const { user } = useContext(userContext);

	useEffect(() => {
		let isMounted = true;

		const refreshAccessToken = async () => {
			try {
				await refresh();
			} catch (error) {
				// handle error
			} finally {
				isMounted && setIsLoading(false);
			}
		};

		!user?.accessToken ? refreshAccessToken() : setIsLoading(false);

		return () => (isMounted = false); // cleanup
	}, []);

	return <>{isLoading ? <p className='footer'>Loading...</p> : <Outlet />}</>;
};

export default PersistLogin;
