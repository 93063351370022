import { formatDate } from '../utils/helper';
import Pagination from '../components/Pagination.js';
import { useState, useEffect, useRef, useContext } from 'react';
import { getLogs, getLogCount, getLocations, emailLogs } from '../api/urls.js';
import { CSVLink } from 'react-csv';
import { FaFilter } from 'react-icons/fa';
import { FaSearch } from 'react-icons/fa';
import { BiSolidEraser } from 'react-icons/bi';
import { HiOutlineShare } from 'react-icons/hi';
import { HiOutlineMail } from 'react-icons/hi';
import { IoMdDownload } from 'react-icons/io';
import { UserContext } from '../context/userContext.js';
import useAxiosPrivate from '../hooks/useAxiosPrivate.js';

function Logs() {
	const { user } = useContext(UserContext);
	const selectLocation = '--select--';
	const [logs, setLogs] = useState([]);
	const [limit, setLimit] = useState(10); // for pagination
	const [page, setPage] = useState(1); // for pagination
	const [numPage, setNumPage] = useState(1); // for pagination
	const [loading, setLoading] = useState(true);
	const [refresh, setRefresh] = useState(false);
	const [count, setCount] = useState(false);
	const [location, setLocation] = useState(selectLocation);
	const [locationList, setLocationList] = useState([]);
	const [showFilter, setShowFilter] = useState(false);
	const [filterData, setFilterData] = useState({
		name: '',
		dateMin: '',
		dateMax: '',
		timeMin: '',
		timeMax: '',
		type: '',
	});
	const [csvData, setCsvData] = useState([]);
	const [shareOpen, setShareOpen] = useState(false);
	const [emailRecieve, setEmailRecieve] = useState('');
	const [emailError, setEmailError] = useState('');
	const [emailSuccess, setEmailSuccess] = useState('');
	const [isSendingEmail, setIsSendingEmail] = useState(false);
	const csvLinkRef = useRef();
	const axiosPrivate = useAxiosPrivate();

	// Fetch location list once when the component mounts
	useEffect(() => {
		async function fetchData() {
			try {
				const response = await axiosPrivate.get(getLocations(user.company_id));
				setLocationList(response.data);
			} catch (error) {
				// handle error
			}
		}

		fetchData();
	}, [axiosPrivate, user.company_id]);

	useEffect(() => {
		async function fetchLogs() {
			try {
				setLoading(true);
				if (location !== selectLocation) {
					const filters = {
						...filterData,
						all: false,
						page,
						limit,
					};
					const responseLogs = await axiosPrivate.get(
						getLogs(user.company_id, location, filters)
					);
					setLogs(responseLogs.data);
				} else {
					setLogs([]);
					setCount(0);
					setNumPage(1);
				}
			} catch (error) {
				// Handle error
			} finally {
				setLoading(false);
			}
		}

		fetchLogs();
	}, [refresh, page, limit, location, user.company_id, axiosPrivate]);

	useEffect(() => {
		async function fetchCount() {
			try {
				if (location !== selectLocation) {
					const responseCount = await axiosPrivate.get(
						getLogCount(user.company_id, location, filterData)
					);
					setCount(responseCount.data.count);
					setNumPage(Math.ceil(responseCount.data.count / limit));
				}
			} catch (error) {
				// Handle error
			}
		}

		fetchCount();
	}, [location, user.company_id, axiosPrivate, limit]);

	function handleLimitSelect(event) {
		setLimit(event.target.value);
		setPage(1);
	}

	function handleChange(event) {
		const { name, value } = event.target;
		setFilterData({
			...filterData,
			[name]: value,
		});
	}

	function handleSearch() {
		// refresh logs
		setRefresh((prev) => !prev);
	}

	function handleEmailChange(event) {
		setEmailRecieve(event.target.value);
		setEmailError('');
		setEmailSuccess('');
	}

	useEffect(() => {
		// trigger local download of csv file when data is finished loading
		if (csvData.length > 0) {
			csvLinkRef.current.link.click();
		}
	}, [csvData]);

	async function getCsvFriendly() {
		// Format all log data across all pages into CSV friendly format (array of arrays)
		const filters = {
			...filterData,
			all: true,
		};
		try {
			const allLogsData = await axiosPrivate.get(
				getLogs(user.company_id, location, filters)
			);

			const csvFriendly = [
				['Log #', 'ID', 'Name', 'Time', 'Date', 'Type'],
				...allLogsData.map((log) => [
					log.id,
					log.employee_id,
					`${log.first_name} ${log.last_name}`,
					formatDate(log.time, 'time'),
					formatDate(log.time, 'date'),
					log.type,
				]),
			];
			return csvFriendly;
		} catch (error) {
			return [];
		}
	}

	async function handleDownloadCsv() {
		try {
			const csvFriendly = await getCsvFriendly();
			setCsvData(csvFriendly); // this triggers useEffect which triggers csvLinkRef
		} catch (error) {
			setCsvData([]);
		}
	}

	async function handleEmailLogs() {
		setEmailError('');
		setEmailSuccess('');
		if (emailRecieve === '') {
			setEmailError('Please enter email.');
			return;
		}

		try {
			setIsSendingEmail(true);
			const body = {
				to: emailRecieve,
				subject: 'Logs',
				text: 'Attached is a csv file containing the requested logs. Do not reply to this email.',
				csvData: await getCsvFriendly(),
			};
			await emailLogs(body);
			setEmailSuccess('Email sent successfully');
		} catch (error) {
			setEmailError('Email failed to send.');
		} finally {
			setIsSendingEmail(false);
		}
	}

	return (
		<main className='logs-main'>
			<div className='title'>
				<h2>Logs for location</h2>
				{/* select location */}
				<select
					className='location-select'
					value={location}
					onChange={(event) => setLocation(event.target.value)}
				>
					<option>{selectLocation}</option>

					{locationList.length > 0
						? locationList.map((location) => (
								<option key={location.name}>{location.name}</option>
						  ))
						: ''}
				</select>
			</div>

			<hr />
			{/* search  name/ID */}
			<div className='search'>
				<div className='search-top'>
					<label>Search:</label>
					<input
						type='text'
						placeholder='Name/ID'
						value={filterData.name}
						name='name'
						onChange={handleChange}
					></input>

					<button
						className='btn btn-search'
						onClick={handleSearch}
					>
						<FaSearch />
					</button>

					{/* show filters button */}
					<button
						className={
							showFilter ? 'btn btn-filters btn-clicked' : 'btn btn-filters'
						}
						onClick={() => setShowFilter((prevState) => !prevState)}
					>
						<FaFilter className='icon-filter' />
						{showFilter ? 'Hide Filters' : 'Show Filters'}
					</button>
				</div>

				{showFilter ? (
					<>
						{/* filters */}
						<div className='filter-options'>
							<hr />

							<div>
								<label>Date range:</label>
								<input
									type='date'
									value={filterData.dateMin}
									name='dateMin'
									onChange={handleChange}
								></input>
								<label>to</label>
								<input
									type='date'
									value={filterData.dateMax}
									name='dateMax'
									onChange={handleChange}
								></input>
							</div>

							<div>
								<label>Time range:</label>
								<input
									type='time'
									value={filterData.timeMin}
									name='timeMin'
									onChange={handleChange}
								></input>
								<label>to</label>
								<input
									type='time'
									value={filterData.timeMax}
									name='timeMax'
									onChange={handleChange}
								></input>
							</div>

							<div>
								<label>Type:</label>
								<select
									value={filterData.type}
									name='type'
									onChange={handleChange}
								>
									<option>-</option>
									<option>arrive</option>
									<option>leave</option>
								</select>
							</div>
						</div>

						{/* clear filters */}
						<button
							className='btn'
							onClick={() =>
								setFilterData({
									name: '',
									dateMin: '',
									dateMax: '',
									timeMin: '',
									timeMax: '',
									type: '',
								})
							}
						>
							<BiSolidEraser /> Clear Filters
						</button>
					</>
				) : null}
			</div>

			<div className='row-info'>
				{/* total matching rows found */}
				<p>{count || 0} rows to display</p>

				{/* select how many logs to display per page */}
				<div>
					<label>Logs per page</label>
					<select
						value={limit}
						onChange={handleLimitSelect}
					>
						<option>5</option>
						<option>10</option>
						<option>25</option>
						<option>50</option>
						<option>100</option>
					</select>
				</div>

				{/* refresh data */}
				<button
					className='refresh'
					onClick={() => setRefresh(!refresh)}
				>
					<span className='refresh-symbol'>⟳</span>
					{loading ? 'Loading...' : 'Refresh'}
				</button>

				<div className='share-logs'>
					{/* share button */}
					{logs.length > 0 ? (
						<button
							className={shareOpen ? 'btn btn-clicked' : 'btn'}
							onClick={() => {
								setShareOpen(!shareOpen);
								setEmailError('');
								setEmailSuccess('');
							}}
						>
							<HiOutlineShare className='icon-bigger' />
							Share
						</button>
					) : (
						// disabled share button
						<button className='btn btn-disabled'>
							<HiOutlineShare className='icon-bigger' />
							Share
						</button>
					)}

					{/* share options/dropdown */}
					{shareOpen && location !== selectLocation && (
						<div className='share-dropdown'>
							{/* email */}
							<div>
								<HiOutlineMail /> Email to
								<input
									type='text'
									onChange={handleEmailChange}
									value={emailRecieve}
								/>
								<button
									className={isSendingEmail ? 'btn btn-disabled' : 'btn'}
									onClick={handleEmailLogs}
									disabled={isSendingEmail}
								>
									{isSendingEmail ? 'Sending...' : 'Send'}
								</button>
							</div>

							{/* feeback message */}
							<p className='red'>{emailError}</p>
							<p className='green'>{emailSuccess}</p>

							<hr />

							{/* download csv locally*/}
							<button
								className='download-link'
								onClick={handleDownloadCsv}
							>
								<IoMdDownload /> Download CSV
							</button>
							{/* not shown but activated by csvLinkRef.current.link.click() */}
							<CSVLink
								ref={csvLinkRef}
								filename='logs.csv'
								data={csvData}
							></CSVLink>

							{/* close share dropdown */}
							<button
								className='close-dropdown'
								onClick={() => setShareOpen(false)}
							>
								Close
							</button>
						</div>
					)}
				</div>
			</div>

			{/* logs table*/}
			<table className='logs-table'>
				<thead>
					<tr>
						<th>#</th>
						<th>ID</th>
						<th>Name</th>
						<th>Time</th>
						<th>Date</th>
						<th>Type</th>
					</tr>
				</thead>
				<tbody>
					{logs.length > 0 ? (
						logs.map((log) => (
							<tr key={log.id}>
								<td>{log.id}</td>
								<td>{log.employee_id}</td>
								<td>
									{log.first_name} {log.last_name}
								</td>
								<td>{formatDate(log.time, 'time')}</td>
								<td>{formatDate(log.time, 'date')}</td>
								<td className={log.type === 'arrive' ? 'green' : 'red'}>
									{log.type}
								</td>
							</tr>
						))
					) : (
						<tr>
							<td
								className='no-logs'
								colSpan='6'
							>
								{location === selectLocation
									? 'Please select a location.'
									: 'No Logs to Display'}
							</td>
						</tr>
					)}
				</tbody>
			</table>

			{/* pagination */}
			<Pagination
				page={page}
				numPage={numPage}
				setPage={setPage}
			/>
		</main>
	);
}

export default Logs;
