import { axiosPrivate } from '../api/axios';
import { logout } from '../api/urls';
import { useNavigate } from 'react-router-dom';
import UserContext from '../context/userContext';
import { useContext } from 'react';

const useLogout = () => {
	const navigate = useNavigate();
	const { setUser } = useContext(UserContext);

	const handleLogout = async () => {
		try {
			await axiosPrivate.get(logout);
		} catch (error) {
			// handle error
		} finally {
			setUser(null);
			navigate('/login');
		}
	};

	return handleLogout;
};

export default useLogout;
