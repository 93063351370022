import { useState, useEffect, useContext } from 'react';
import { getLocations, updateLocationConfig } from '../api/urls.js';
import { UserContext } from '../context/userContext.js';
import useAxiosPrivate from '../hooks/useAxiosPrivate.js';

function Config() {
	const { user } = useContext(UserContext);
	const [locations, setLocations] = useState([]);
	const axiosPrivate = useAxiosPrivate();
	const [formData, setFormData] = useState({
		username: '',
		password: '',
		password_confirm: '',
	});
	const [userFormData, setUserFormData] = useState({
		emp_id: '',
		f_name: '',
		l_name: '',
	});
	const [errorMsg, setErrorMsg] = useState('');
	const [successMsg, setSuccessMsg] = useState('');
	const [errorMsgUser, setErrorMsgUser] = useState('');
	const [successMsgUser, setSuccessMsgUser] = useState('');

	useEffect(() => {
		async function fetchData() {
			try {
				const response = await axiosPrivate.get(getLocations(user.company_id));
				setLocations(response.data);
			} catch (error) {
				// handle error
			}
		}

		fetchData();
	}, [axiosPrivate, user.company_id]);

	async function handleCheckboxChange(event, index) {
		const newLocations = [...locations];
		newLocations[index].banning = event.target.checked;
		setLocations(newLocations);

		try {
			await axiosPrivate.put(
				updateLocationConfig(
					newLocations[index].id,
					newLocations[index].banning
				)
			);
		} catch (error) {
			// handle error
		}
	}

	async function handleFormChange(event) {
		const { name, value } = event.target;
		setFormData({
			...formData,
			[name]: value,
		});
		//clear error
		setErrorMsg('');
	}

	async function handleUserFormChange(event) {
		const { name, value } = event.target;
		setUserFormData({
			...userFormData,
			[name]: value,
		});
		//clear error
		setErrorMsgUser('');
	}

	async function handleCreateAdmin(event) {
		event.preventDefault(); // stop automatic page refresh
		// clear feedback messages
		setErrorMsg('');
		setSuccessMsg('');

		if (
			formData.username === '' ||
			formData.password === '' ||
			formData.password_confirm === ''
		) {
			setErrorMsg('Cannot leave a field blank.');
			return;
		}

		if (formData.password !== formData.password_confirm) {
			setErrorMsg('Passwords do not match.');
			return;
		}

		try {
			await axiosPrivate.post('admin/create', formData);
			setSuccessMsg('Success!');
			// clear form
			setFormData({
				username: '',
				password: '',
				password_confirm: '',
			});
		} catch (error) {
			if (error?.response?.status === 409) {
				setErrorMsg('Admin already exists with that username.');
				return;
			}
			setErrorMsg('An error occured.');
		}
	}

	async function handleCreateUser(event) {
		event.preventDefault(); // stop automatic page refresh
		// clear feedback messages
		setErrorMsgUser('');
		setSuccessMsgUser('');

		if (
			userFormData.emp_id === '' ||
			userFormData.f_name === '' ||
			userFormData.l_name === ''
		) {
			setErrorMsgUser('Cannot leave a field blank.');
			return;
		}

		try {
			await axiosPrivate.post('users/create', userFormData);
			setSuccessMsgUser('Success!');
			// clear form
			setUserFormData({
				emp_id: '',
				f_name: '',
				l_name: '',
			});
		} catch (error) {
			if (error?.response?.status === 409) {
				setErrorMsgUser('User already exists with that employee ID.');
				return;
			}
			setErrorMsgUser('An error occured.');
		}
	}

	return (
		<main>
			{/* change banning etc */}
			<h2>Configure Location Settings</h2>
			<hr />
			{locations.length ? (
				locations.map((location, index) => (
					<div
						className='config-location-container'
						key={location.id}
					>
						<h4>{location.name}</h4>
						<hr />
						<label>
							Banning:
							<input
								type='checkbox'
								checked={location.banning}
								onChange={(event) => handleCheckboxChange(event, index)}
							/>
						</label>
					</div>
				))
			) : (
				<p>No locations available.</p>
			)}

			{/* add admin */}
			<h2 className='create-admin-heading'>Add New Admin</h2>
			<hr />
			<form
				onSubmit={handleCreateAdmin}
				className='create-admin-form'
			>
				<label>
					Username
					<input
						type='text'
						name='username'
						placeholder='username'
						value={formData.username}
						onChange={handleFormChange}
					/>
				</label>

				<label>
					Password
					<input
						type='password'
						name='password'
						placeholder='**********'
						value={formData.password}
						onChange={handleFormChange}
					/>
				</label>

				<label>
					Confirm password
					<input
						type='password'
						name='password_confirm'
						placeholder='**********'
						value={formData.password_confirm}
						onChange={handleFormChange}
					/>
				</label>

				<button
					type='submit'
					className='btn'
				>
					Submit
				</button>

				<p className='error'>{errorMsg}</p>
				<p className='success'>{successMsg}</p>
			</form>

			{/* add user */}
			<h2 className='create-admin-heading'>Add New User</h2>
			<hr />
			<form
				onSubmit={handleCreateUser}
				className='create-admin-form'
			>
				<label>
					Employee ID
					<input
						type='text'
						name='emp_id'
						placeholder='1001'
						value={userFormData.emp_id}
						onChange={handleUserFormChange}
					/>
				</label>

				<label>
					First name
					<input
						type='text'
						name='f_name'
						placeholder='John'
						value={userFormData.f_name}
						onChange={handleUserFormChange}
					/>
				</label>

				<label>
					Last name
					<input
						type='text'
						name='l_name'
						placeholder='Doe'
						value={userFormData.l_name}
						onChange={handleUserFormChange}
					/>
				</label>

				<button
					type='submit'
					className='btn'
				>
					Submit
				</button>

				<p className='error'>{errorMsgUser}</p>
				<p className='success'>{successMsgUser}</p>
			</form>
		</main>
	);
}

export default Config;
