import axios from 'axios';

const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;

export default axios.create({
	baseURL: BASE_API_URL,
	withCredentials: true, // sends cookies
});

export const axiosPrivate = axios.create({
	baseURL: BASE_API_URL,
	headers: {
		'Content-Type': 'application/json',
	},
	withCredentials: true, // sends cookies
});
