import { axiosPrivate } from '../api/axios';
import { useEffect, useContext } from 'react';
import useNewAccessToken from './useNewAccessToken';
import UserContext from '../context/userContext';
import useLogout from './useLogout';

const useAxiosPrivate = () => {
	const refresh = useNewAccessToken();
	const { user } = useContext(UserContext);
	const logout = useLogout();

	useEffect(() => {
		// intercept request and add access token to header
		const requestIntercept = axiosPrivate.interceptors.request.use(
			(config) => {
				if (!config.headers['Authorization']) {
					config.headers['Authorization'] = `Bearer ${user?.accessToken}`;
				}
				return config;
			},
			(error) => Promise.reject(error)
		);

		// intercept response and check if error for bad token
		const responseIntercept = axiosPrivate.interceptors.response.use(
			(response) => response, // response was successful, don't do anything
			async (error) => {
				const prevRequest = error?.config;
				if (error?.response?.status === 401 && !prevRequest?.sent) {
					// Access token expired or invalid, attempt to refresh
					prevRequest.sent = true; // Prevent infinite loops
					try {
						const newAccessToken = await refresh();
						prevRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
						return axiosPrivate(prevRequest);
					} catch (error) {
						// refresh token expired, logout
						if (error.response.status === 401) {
							logout();
						}
					}
				}
				return Promise.reject(error);
			}
		);
		// cleanup function
		return () => {
			axiosPrivate.interceptors.request.eject(requestIntercept);
			axiosPrivate.interceptors.response.eject(responseIntercept);
		};
	}, [refresh, user]);

	return axiosPrivate;
};

export default useAxiosPrivate;
