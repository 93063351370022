import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { UserProvider } from './context/userContext';
import Header from './components/Header';
import Dashboard from './pages/Dashboard';
import Onsite from './pages/Onsite';
import Login from './pages/Login';
import Logs from './pages/Logs';
import Alert from './pages/Alert';
import User from './pages/User';
import Incidents from './pages/incidents/Incidents';
import IncidentsCreate from './pages/incidents/IncidentsCreate';
import IncidentsAction from './pages/incidents/IncidentsAction';
import IncidentsDetails from './pages/incidents/IncidentsDetails';
import Config from './pages/Config';
import Footer from './components/Footer';
import Missing from './pages/Missing';
import ProtectedRoutes from './components/ProtectedRoutes';
import PersistLogin from './components/PersistLogin';
// css
import './css/App.css';
import './css/header.css';
import './css/login.css';
import './css/onsite.css';
import './css/logs.css';
import './css/footer.css';
import './css/alert.css';
import './css/users.css';
import './css/incidents.css';
import './css/dashboard.css';
import './css/config.css';

function App() {
	return (
		<div className='app'>
			<UserProvider>
				<BrowserRouter>
					<Header />
					<Routes>
						<Route
							path='/login'
							element={<Login />}
						/>
						{/* protected routes */}
						<Route element={<PersistLogin />}>
							<Route
								path='/'
								element={<ProtectedRoutes />}
							>
								<Route
									path='/'
									element={<Dashboard />}
								/>
								<Route
									path='/onsite'
									element={<Onsite />}
								/>
								<Route
									path='/logs'
									element={<Logs />}
								/>
								<Route
									path='/alert'
									element={<Alert />}
								/>
								<Route
									path='/incidents'
									element={<Incidents />}
								/>
								<Route
									path='/incidents-create'
									element={<IncidentsCreate />}
								/>
								<Route
									path='/incidents-action/:incidentId'
									element={<IncidentsAction />}
								/>
								<Route
									path='/incidents-view/:incidentId'
									element={<IncidentsDetails />}
								/>
								<Route
									path='/user/:userId'
									element={<User />}
								/>
								<Route
									path='/config'
									element={<Config />}
								/>
							</Route>
						</Route>
						{/* catch all */}
						<Route
							path='*'
							element={<Missing />}
						/>
					</Routes>
				</BrowserRouter>
				<Footer />
			</UserProvider>
		</div>
	);
}

export default App;
