import { Outlet, Navigate } from 'react-router-dom';
import UserContext from '../context/userContext';
import { useContext } from 'react';

function ProtectedRoutes() {
	const { user } = useContext(UserContext);

	return user ? <Outlet /> : <Navigate to='/login' />;
}

export default ProtectedRoutes;
