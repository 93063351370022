function Pagination({ page, numPage, setPage }) {
	/* 
    page is current page, numPage is how many pages are there and setPage sets current page.
    */
	return (
		<div className='pages'>
			{page > 4 ? (
				<>
					{/* show page 1 */}
					<div
						className='page'
						onClick={() => setPage(1)}
					>
						1...
					</div>
					{/* show the previous 3 pages if middle page */}
					<div
						className='page'
						onClick={() => setPage(page - 3)}
					>
						{page - 3}
					</div>
					<div
						className='page'
						onClick={() => setPage(page - 2)}
					>
						{page - 2}
					</div>
					<div
						className='page'
						onClick={() => setPage(page - 1)}
					>
						{page - 1}
					</div>
				</>
			) : (
				<>
					{/* make sure to not show negative page numbers */}
					{Array.from({ length: page - 1 }, (_, i) => i).map((i) => (
						<div
							key={i}
							className='page'
							onClick={() => setPage(i + 1)}
						>
							{i + 1}
						</div>
					))}
				</>
			)}

			{/* current page */}
			<div
				className='page active-page'
				onClick={() => setPage(page)}
			>
				{page}
			</div>

			{numPage - page > 4 ? (
				<>
					{/* show the next 3 pages if a middle page*/}
					<div
						className='page'
						onClick={() => setPage(page + 1)}
					>
						{page + 1}
					</div>
					<div
						className='page'
						onClick={() => setPage(page + 2)}
					>
						{page + 2}
					</div>
					<div
						className='page'
						onClick={() => setPage(page + 3)}
					>
						{page + 3}
					</div>
					{/* last page */}
					<div
						className='page'
						onClick={() => setPage(numPage)}
					>
						...{numPage}
					</div>
				</>
			) : (
				<>
					{/* make sure to not show page numbers over last page*/}
					{Array.from({ length: numPage - page }, (_, i) => i).map((i) => (
						<div
							key={i}
							className='page'
							onClick={() => setPage(page + i + 1)}
						>
							{page + i + 1}
						</div>
					))}
				</>
			)}
		</div>
	);
}

export default Pagination;
