import { formatDate } from '../../utils/helper.js';
import { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { getIncidentOne, getLogs } from '../../api/urls.js';
import { FaArrowLeft } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { UserContext } from '../../context/userContext.js';
import useAxiosPrivate from '../../hooks/useAxiosPrivate.js';

function IncidentsDetails() {
	const { user } = useContext(UserContext);
	const [incidentInfo, setIncidentInfo] = useState(null);
	const [logs, setLogs] = useState([]);
	const { incidentId } = useParams();
	const navigate = useNavigate();
	const axiosPrivate = useAxiosPrivate();

	useEffect(() => {
		async function fetchData() {
			try {
				const response = await axiosPrivate.get(getIncidentOne(incidentId));
				const incident = response.data;
				setIncidentInfo(incident);

				// if incident has location, get logs withitn that time range at that location
				if (incident.location) {
					const filers = {
						dateMin: incident.dateStart,
						dateMax: incident.dateEnd,
					};
					const response = await axiosPrivate.get(
						getLogs(user.company_id, incident.location, filers)
					);
					setLogs(response.data);
				}
			} catch (error) {
				// handle error
			}
		}

		fetchData();
	}, [axiosPrivate, incidentId, user.company_id]);

	return (
		<main>
			<div className='create-incident-top'>
				<h2>View Incident</h2>
				<button
					className='btn back-btn'
					onClick={() => navigate('/incidents')}
				>
					<FaArrowLeft />
					Go Back
				</button>
			</div>
			<hr />
			{incidentInfo ? (
				<>
					<div className='info-group'>
						<h4>Incident No.</h4>
						<p>{incidentInfo.id}</p>
					</div>

					<div className='info-group'>
						<h4>Title</h4>
						<p>{incidentInfo.title}</p>
					</div>

					<div className='info-group'>
						<h4>Description</h4>
						<p>{incidentInfo.description}</p>
					</div>

					<div className='info-group'>
						<h4>Date & Time Reported</h4>
						<p>{formatDate(incidentInfo.created_at)}</p>
					</div>

					<div className='info-group'>
						<h4>Reported By</h4>
						<p>
							{incidentInfo.first_name} {incidentInfo.last_name}
						</p>
					</div>

					<div className='info-group'>
						<h4>Location of Incident</h4>
						<p>{incidentInfo.location || 'N/A'}</p>
					</div>

					<div className='info-group'>
						<h4>Incident Date Range</h4>
						<p>
							{formatDate(incidentInfo.dateStart, 'date')} to{' '}
							{formatDate(incidentInfo.dateEnd, 'date')}
						</p>
					</div>

					<div className='info-group'>
						<h4>Status</h4>
						<p>{incidentInfo.status}</p>
					</div>

					{/* logs during incident */}
					<h3 className='recent-logs-heading'>Logs During Incident Dates</h3>
					<hr />
					<table>
						<thead>
							<tr>
								<th>Log #</th>
								<th>Name</th>
								<th>Date & Time</th>
								<th>Type</th>
							</tr>
						</thead>
						<tbody>
							{logs.length > 0 ? (
								logs.map((log) => (
									<tr key={log.id}>
										<td>{log.id}</td>
										<td>
											{log.first_name} {log.last_name}
										</td>
										<td>{formatDate(log.time)}</td>
										<td className={log.type === 'arrive' ? 'green' : 'red'}>
											{log.type}
										</td>
									</tr>
								))
							) : (
								<tr>
									<td
										className='no-logs'
										colSpan='5'
									>
										{incidentInfo.location
											? 'No logs during incident time'
											: 'No location given'}
									</td>
								</tr>
							)}
						</tbody>
					</table>
				</>
			) : (
				<p>Cannot find details on this incident.</p>
			)}
		</main>
	);
}

export default IncidentsDetails;
