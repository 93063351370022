import { sendAlert, getLocations } from '../api/urls.js';
import { useState, useEffect, useContext } from 'react';
import { IoMdCheckboxOutline } from 'react-icons/io';
import { MdCheckBoxOutlineBlank } from 'react-icons/md';
import { UserContext } from '../context/userContext.js';
import useAxiosPrivate from '../hooks/useAxiosPrivate.js';
const _ = require('lodash'); // used to compare sets

function Alert() {
	const { user } = useContext(UserContext);
	const [formData, setFormData] = useState({
		title: '',
		message: '',
	});
	const [locationList, setLocationList] = useState([]);
	const [selectedLocations, setSelectedLocations] = useState([]);
	const [errorMsg, setErrorMsg] = useState('');
	const [confirmationMsg, setConfirmationMsg] = useState('');
	const [allChecked, setAllChecked] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const axiosPrivate = useAxiosPrivate();

	// Fetch location list once when the component mounts
	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axiosPrivate.get(getLocations(user.company_id));
				setLocationList(response.data);
			} catch (error) {
				// handle error
			}
		};

		fetchData();
	}, [axiosPrivate, user.company_id]);

	function handleChange(event) {
		const { name, value } = event.target;
		setFormData({
			...formData,
			[name]: value,
		});
		//clear error
		setErrorMsg('');
	}

	async function handleSubmit(event) {
		event.preventDefault(); // stop browser page reload
		// clear feedback messages
		setErrorMsg('');
		setConfirmationMsg('');
		// check fields not left blank
		if (
			formData.title === '' ||
			formData.message === '' ||
			selectedLocations.length === 0
		) {
			setErrorMsg('Cannot leave any fields blank.');
			return;
		}

		try {
			setIsLoading(true);
			const alert = {
				title: formData.title,
				message: formData.message,
				locations: selectedLocations,
			};

			const response = await axiosPrivate.post(sendAlert, alert);
			const data = response.data;
			const fails = data.failureCount;
			const successes = data.successCount;
			// feedback message depending on success
			if (fails > 0 && successes === 0) {
				setErrorMsg(`Sent with ${successes} successes and ${fails} failures.`);
			} else if (fails === 0 && successes > 0) {
				setConfirmationMsg(`Succesfully sent to ${successes} devices.`);
			} else if (fails > 0 && successes > 0) {
				setConfirmationMsg(
					`Sent with ${successes} successes and ${fails} failures.`
				);
			} else {
				setErrorMsg('Failed to send alerts.');
			}

			// clear form
			setFormData({
				title: '',
				message: '',
			});
		} catch (error) {
			if (error.message) {
				setErrorMsg(error.message);
			} else {
				setErrorMsg('There was an error sending alerts.');
			}
		} finally {
			setIsLoading(false);
		}
	}

	function handleLocationClick(location_id) {
		if (!selectedLocations.includes(location_id)) {
			// add location to selected locations
			setSelectedLocations([...selectedLocations, location_id]);

			// if now all locations are checked, highlight 'All'
			const allIds = [];
			locationList.forEach((location) => {
				allIds.push(location.id);
			});

			// if all locations have now been selected, set 'All' to checked
			if (
				_.isEqual(new Set(allIds), new Set([...selectedLocations, location_id]))
			) {
				setAllChecked(true);
			}
		} else {
			// remove location from selected locations
			const newSelectedLocations = selectedLocations.filter(
				(location) => location !== location_id
			);
			setSelectedLocations(newSelectedLocations);

			setAllChecked(false); // All option no longer highlighted
		}
	}

	function handleLocationClickAll() {
		if (!allChecked) {
			// select all locations
			const allIds = [];
			locationList.forEach((location) => {
				allIds.push(location.id);
			});
			setSelectedLocations(allIds);
		} else {
			// remove all selected locations
			setSelectedLocations([]);
		}

		setAllChecked((prev) => !prev);
	}

	function renderAllButton() {
		if (locationList.length > 0) {
			if (allChecked) {
				return (
					<button
						className='location-btn location-selected'
						type='button'
						onClick={handleLocationClickAll}
					>
						All
						<IoMdCheckboxOutline className='check-icon' />
					</button>
				);
			} else {
				return (
					<button
						className='location-btn location-unselected'
						type='button'
						onClick={handleLocationClickAll}
					>
						All
						<MdCheckBoxOutlineBlank className='check-icon' />
					</button>
				);
			}
		}

		return null;
	}

	return (
		<main className='alert-main'>
			<h2>Send Alerts</h2>
			<hr />
			<p>
				Send a push notification to the app of employees at selected locations.
			</p>

			<form onSubmit={handleSubmit}>
				<h4>Select Locations</h4>
				<p>Click on the locations you want to send the alert to.</p>
				{/* locations selection */}
				<div className='locations-parent'>
					{/* all option */}
					{renderAllButton()}

					{/* all other locations */}
					{locationList.length > 0 ? (
						locationList.map((location) => {
							const isSelected = selectedLocations.includes(location.id);
							return (
								<button
									className={`location-btn ${
										isSelected ? 'location-selected' : 'location-unselected'
									}`}
									type='button'
									key={location.id}
									onClick={() => handleLocationClick(location.id)}
								>
									{location.name}
									{isSelected ? (
										<IoMdCheckboxOutline className='check-icon' />
									) : (
										<MdCheckBoxOutlineBlank className='check-icon' />
									)}
								</button>
							);
						})
					) : (
						<p>No Locations found.</p>
					)}
				</div>

				{/* alert form */}
				<h4>Title</h4>
				<input
					type='text'
					className='title-box'
					placeholder='Alert title'
					name='title'
					onChange={handleChange}
					value={formData.title}
				/>

				<h4>Message</h4>
				<textarea
					className='message-box'
					rows='5'
					placeholder='Alert message'
					name='message'
					onChange={handleChange}
					value={formData.message}
				></textarea>

				{isLoading ? (
					<button
						className='btn btn-disabled alert-btn'
						type='submit'
						disabled='true'
					>
						Sending...
					</button>
				) : (
					<button
						className='btn alert-btn'
						type='submit'
					>
						Send Alert
					</button>
				)}
			</form>

			{/* feedback messages */}
			<p className='error'>{errorMsg}</p>
			<p className='success'>{confirmationMsg}</p>
		</main>
	);
}

export default Alert;
