// Endpoints for Onsite-related actions
export const getOnsiteAll = (companyId, name) =>
	`/onsite/${companyId}?name=${name}`;
export const getOnsite = (companyId, location, name) =>
	`/onsite/${companyId}/${location}?name=${name}`;
export const getOnsiteCount = (companyId) => `/onsite/count/${companyId}`;

// Endpoints for Logs-related actions
export const getLogs = (companyId, location, filters) => {
	const queryParams = new URLSearchParams(filters).toString();
	return `/logs/${companyId}/${location}?${queryParams}`;
};
export const getLogCount = (companyId, location, filters) => {
	const queryParams = new URLSearchParams(filters).toString();
	return `/logs/count/${companyId}/${location}?${queryParams}`;
};
export const emailLogs = `/logs/email-logs`;

// Endpoints for User-related actions
export const searchUsers = (companyId, search) =>
	`/users/search/${companyId}?search=${search}`;
export const getUser = (id) => `/users/${id}`;
export const getUsers = `/users/`;
export const getPinnedUsers = `/users/pinned/`;
export const getLogsForUser = (userId) => `/logs/${userId}`;

// Endpoints for Incidents-related actions
export const getIncidentOne = (id) => `/incidents/single/${id}`;
export const getIncidents = (companyId) => `/incidents/${companyId}`;
export const getIncidentsCount = (companyId) => `/incidents/${companyId}/count`;
export const createIncident = `/incidents`;

// Endpoints for Locations-related actions
export const getLocations = (companyId) => `/locations/${companyId}`;
export const updateLocationConfig = (locationId, banning) =>
	`/locations/${locationId}/${banning}`;

// Authentication and Authorization Endpoints
export const authenticateAdmin = `/admin/authenticate`;
export const refreshAccessToken = `/auth/refresh-token`;
export const logout = `/admin/logout`;

// Alert-related actions
export const sendAlert = `/alert`;
