import axios from '../api/axios';
import { useContext } from 'react';
import { UserContext } from '../context/userContext';
import { jwtDecode } from 'jwt-decode';

function useNewAccessToken() {
    const { setUser } = useContext(UserContext);

    async function refresh() {
        const response = await axios.get('/auth/refresh-token');
        const accessToken = response.data.accessToken;
        // update user's access token
        const decoded = jwtDecode(accessToken);
        setUser({ ...decoded, accessToken: accessToken });

        return accessToken;
    }

    return refresh;
};

export default useNewAccessToken;