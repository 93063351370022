import { formatDate } from '../utils/helper';
import { useState, useEffect, useContext } from 'react';
import { getOnsite, getLocations, getOnsiteAll } from '../api/urls.js';
import { FaUser } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../context/userContext.js';
import useAxiosPrivate from '../hooks/useAxiosPrivate.js';

function Onsite() {
	const { user } = useContext(UserContext);
	const selectLocation = '--select--';
	const [employees, setEmployees] = useState([]);
	const [loading, setLoading] = useState(true);
	const [refresh, setRefresh] = useState(false);
	const [location, setLocation] = useState(selectLocation);
	const [searchName, setSearchName] = useState('');
	const [locationList, setLocationList] = useState([]);
	const navigate = useNavigate();
	const axiosPrivate = useAxiosPrivate();

	useEffect(() => {
		// remember last entered option
		const storedOption = localStorage.getItem('selectedLocation');
		if (storedOption) {
			setLocation(storedOption);
		}
	}, []);

	// Fetch location list once when the component mounts
	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axiosPrivate.get(getLocations(user.company_id));
				setLocationList(response.data);
			} catch (error) {
				// handle error
			}
		};

		fetchData();
	}, [axiosPrivate, user.company_id]);

	useEffect(() => {
		async function fetchData() {
			try {
				setLoading(true);
				if (location === 'All') {
					const response = await axiosPrivate.get(
						getOnsiteAll(user.company_id, searchName)
					);
					setEmployees(response.data);
				} else if (location !== selectLocation) {
					const response = await axiosPrivate.get(
						getOnsite(user.company_id, location, searchName)
					);
					setEmployees(response.data);
				} else {
					// location is the default no option selected
					setEmployees([]);
				}
			} catch (error) {
				// handle error
			} finally {
				setLoading(false);
			}
		}

		fetchData();
	}, [refresh, location, searchName, user.company_id, axiosPrivate]);

	function handleChange(event) {
		setSearchName(event.target.value);
	}

	function handleUserClick(employee) {
		navigate(`/user/${employee.id}`); // redirect to user details page
	}

	function handleLocationChange(event) {
		localStorage.setItem('selectedLocation', event.target.value); // to remember selected value
		setLocation(event.target.value);
	}

	return (
		<main className='onsite-main'>
			<div className='title'>
				<h2>Employees on-site at</h2>
				{/* select location */}
				<select
					className='location-select'
					value={location}
					onChange={handleLocationChange}
				>
					<option>{selectLocation}</option>
					<option>All</option>

					{locationList.length > 0
						? locationList.map((location) => (
								<option key={location.name}>{location.name}</option>
						  ))
						: null}
				</select>
			</div>

			<hr />
			{/* search for employee onsite */}
			<label>Search Employee:</label>
			<input
				className='inpt-text'
				type='text'
				placeholder='Name'
				value={searchName}
				onChange={handleChange}
			></input>

			{/* refresh button */}
			<button
				className='refresh'
				onClick={() => setRefresh((prev) => !prev)}
			>
				⟳ {loading ? 'Loading...' : 'Refresh'}
			</button>

			{/* list employess onsite */}
			{employees.length > 0 ? (
				employees.map((emp) => (
					<button
						className='employee'
						key={emp.id}
						onClick={() => handleUserClick(emp)}
					>
						<FaUser className='user-icon' />
						<div>
							<h4 className='id'>{emp.employee_id}</h4>
							<h3 className='name'>
								{emp.first_name} {emp.last_name}
							</h3>
							{location === 'All' ? (
								<p className='mac'>{emp.location_name}</p>
							) : null}
						</div>
						<div className='since'>
							<p>Since:</p>
							<p>{formatDate(emp.since, 'time')}</p>
						</div>
					</button>
				))
			) : (
				<p className='no-employees'>
					{location === selectLocation
						? 'Please select a location.'
						: 'No employees at this location.'}
				</p>
			)}
		</main>
	);
}

export default Onsite;
