import { formatDate, getStatusClassName } from '../../utils/helper.js';
import { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { getIncidents, getIncidentsCount } from '../../api/urls.js';
import useAxiosPrivate from '../../hooks/useAxiosPrivate.js';
import { CiSquarePlus } from 'react-icons/ci';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { FaEdit } from 'react-icons/fa';
import { UserContext } from '../../context/userContext.js';

function Incidents() {
	const { user } = useContext(UserContext);
	const [incidents, setIncidents] = useState([]);
	const [incidentsCount, setIncidentsCount] = useState({
		open: 'n/a',
		closed: 'n/a',
		'in progress': 'n/a',
	});
	const navigate = useNavigate();
	const axiosPrivate = useAxiosPrivate();

	useEffect(() => {
		async function fetchData() {
			try {
				// get incidents
				const incidentResponse = await axiosPrivate.get(
					getIncidents(user.company_id)
				);
				setIncidents(incidentResponse.data);

				// get count of closed/open/in progress/etc incidents
				const countResponse = await axiosPrivate.get(
					getIncidentsCount(user.company_id)
				);
				setIncidentsCount(countResponse.data);
			} catch (error) {
				// handle error
			}
		}

		fetchData();
	}, [user.company_id, axiosPrivate]);

	function handleCreate() {
		navigate('/incidents-create');
	}

	return (
		<main>
			<h2>Incidents</h2>
			<hr />
			{/* create incident */}
			<button
				className='create-incident'
				onClick={handleCreate}
			>
				<CiSquarePlus className='create-incident-icon' />
				<h3>Create Incident</h3>
			</button>

			{/* Incidents Overview */}
			<h3>Incidents Overview</h3>
			<hr />
			<div className='incident-overview'>
				<div className='incident-overview-box'>
					<p>OPEN INCIDENTS</p>
					<hr />
					<h2 className='red'>{incidentsCount.open || 0}</h2>
				</div>
				<div className='incident-overview-box'>
					<p>IN PROGRESS INCIDENTS</p>
					<hr />
					<h2 className='amber'>{incidentsCount['in progress'] || 0}</h2>
				</div>
				<div className='incident-overview-box'>
					<p>CLOSED INCIDENTS</p>
					<hr />
					<h2 className='green'>{incidentsCount.closed || 0}</h2>
				</div>
			</div>

			{/* incidents  */}
			<h3>Lodged Incidents</h3>
			<hr />
			{/* incident table */}
			<table className='incidents-table'>
				<thead>
					<tr>
						<th>#</th>
						<th>Title</th>
						<th>Date & Time Created</th>
						<th>Submitted by</th>
						<th>Location</th>
						<th>Last Actioned</th>
						<th>Status</th>
						<th></th>
						{/* Actions*/}
					</tr>
				</thead>
				<tbody>
					{incidents.length > 0 ? (
						incidents.map((incident) => (
							<tr key={incident.id}>
								<td>{incident.id}</td>
								<td>{incident.title}</td>
								<td>{formatDate(incident.created_at)}</td>
								<td>
									{incident.first_name} {incident.last_name}
								</td>
								<td>{incident.location || ''}</td>
								<td>{formatDate(incident.last_action)}</td>
								<td className={getStatusClassName(incident.status) + ' bold'}>
									{incident.status}
								</td>
								<td>
									{/* view incidents details */}
									<button
										className='btn action-btn aqua-bg'
										onClick={() => navigate(`/incidents-view/${incident.id}`)}
									>
										<MdOutlineRemoveRedEye className='eye-icon icon-bigger' />
										View
									</button>
									{/* action incident */}
									<button
										className='btn action-btn red-bg'
										onClick={() => navigate(`/incidents-action/${incident.id}`)}
									>
										<FaEdit className='icon-bigger' />
										Action
									</button>
								</td>
							</tr>
						))
					) : (
						<tr>
							<td
								className='no-logs'
								colSpan='8'
							>
								No Incidents Found
							</td>
						</tr>
					)}
				</tbody>
			</table>
		</main>
	);
}

export default Incidents;
