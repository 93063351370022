import { formatDate } from '../utils/helper.js';
import { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { getOnsiteCount, getPinnedUsers, searchUsers } from '../api/urls.js';
import { PieChart } from '@mui/x-charts/PieChart';
import { MdOutlinePushPin } from 'react-icons/md';
import { FaUser } from 'react-icons/fa';
import { RiUnpinLine } from 'react-icons/ri';
import { GrMapLocation } from 'react-icons/gr';
import { FaPlus } from 'react-icons/fa';
import { UserContext } from '../context/userContext.js';
import useAxiosPrivate from '../hooks/useAxiosPrivate.js';

function Dashboard() {
	const { user } = useContext(UserContext);
	const navigate = useNavigate();
	const [pinnedIds, setPinnedIds] = useState(
		JSON.parse(localStorage.getItem('pinned')) || []
	);
	const [pinnedUsers, setPinnedUsers] = useState([]);
	const [locationOverview, setLocationOverview] = useState([]);
	const [totalEmps, setTotal] = useState(0);
	const [searchTerm, setSearchTerm] = useState('');
	const [searchedUsers, setSearchedUsers] = useState([]);
	const [loading, setLoading] = useState(true);
	const [lastUpdated, setLastUpdated] = useState(
		formatDate(new Date(), 'time')
	);
	const axiosPrivate = useAxiosPrivate();

	useEffect(() => {
		// get user data for pinned users
		async function fetchData() {
			try {
				if (pinnedIds.length > 0) {
					setLoading(true);
					const response = await axiosPrivate.post(`/users/pinned/`, pinnedIds);
					setPinnedUsers(response.data);
				} else {
					setPinnedUsers([]);
				}
			} catch (error) {
				// handle error
				console.log(error);
			} finally {
				setLoading(false);
			}
		}

		fetchData();
	}, [pinnedIds, lastUpdated, axiosPrivate]);

	useEffect(() => {
		// get the number of people at different locations and total
		async function fetchData() {
			try {
				const response = await axiosPrivate.get(
					getOnsiteCount(user.company_id)
				);
				const data = response.data;
				setLocationOverview(data);

				// calculate total employees
				let total = 0;
				data.forEach((location) => {
					total += location.count;
				});

				setTotal(total);
			} catch (error) {
				// handle error
			}
		}

		fetchData();
	}, [axiosPrivate, lastUpdated, user.company_id]);

	useEffect(() => {
		// get searched users when trying to find user to pin
		async function fetchData() {
			try {
				if (searchTerm) {
					const response = await axiosPrivate.get(
						searchUsers(user.company_id, searchTerm)
					);
					setSearchedUsers(response.data);
				}
			} catch (error) {
				// handle error
			}
		}

		fetchData();
	}, [axiosPrivate, searchTerm, user.company_id]);

	function unpin(event, id) {
		event.stopPropagation(); // Prevent event bubbling - stops navigating to user details

		const filteredIds = pinnedIds.filter((x) => x !== id);
		setPinnedIds(filteredIds);

		localStorage.setItem('pinned', JSON.stringify(filteredIds));
	}

	function addPin(id) {
		// avoid adding duplicate id
		if (!pinnedIds.includes(id)) {
			const newIds = [...pinnedIds, id];
			setPinnedIds(newIds);

			localStorage.setItem('pinned', JSON.stringify(newIds));
		}
	}

	return (
		<main>
			<div className='title'>
				<h2>Dashboard</h2>

				{/* last updated */}
				<div className='last-updated'>
					<button
						className='refresh'
						onClick={() => setLastUpdated(formatDate(new Date(), 'time'))}
					>
						⟳ {loading ? 'Loading...' : 'Refresh'}
					</button>
					<p className='italic'>Last updated: {lastUpdated}</p>
				</div>
			</div>

			<hr />
			{/* welcome message */}
			<h2 className='welcome-msg'>Hello, {user.username}.</h2>

			<h3>
				<GrMapLocation />
				Onsite Overview
			</h3>
			<hr />
			<div className='overview-container'>
				<div>
					{/* number of employees onsite at each location */}
					<div className='location-overview'>
						{locationOverview.length ? (
							locationOverview.map((loccation) => (
								<div
									className='overview'
									key={loccation.name}
								>
									<p>{loccation.name.toUpperCase()}</p>
									<hr />
									<h3 className='green'>{loccation.count}</h3>
									<p className='green'>onsite</p>
								</div>
							))
						) : (
							<p>No locations to report.</p>
						)}
					</div>

					{/* total employees onsite across all locations */}
					<div className='total-emps overview'>
						<h4>TOTAL EMPLOYEES ONSITE</h4>
						<hr />
						<h1 className='blue'>{totalEmps}</h1>
					</div>
				</div>

				{/* Pie chart */}
				<PieChart
					series={[
						{
							data: locationOverview.map((location) => ({
								value: location.count,
								label: location.name,
							})),
						},
					]}
					width={400}
					height={250}
					tooltip={{ trigger: 'none' }} // currently too big
				/>
			</div>

			{/* Pinned employees */}
			<h3>
				<MdOutlinePushPin />
				Pinned Employees
			</h3>
			<hr />

			{/* add employee via search*/}
			<div className='add-container'>
				<label>Add employee to pinned:</label>
				{/* search box */}
				<input
					className='inpt-text dashboard-inpt'
					type='text'
					placeholder='Name/ID'
					value={searchTerm}
					onChange={(event) => {
						setSearchTerm(event.target.value);
					}}
				></input>

				{/* search results dropdown */}
				<div className='search-results-dropdown'>
					{searchedUsers.length && searchTerm ? (
						searchedUsers.map((emp) => (
							<p
								key={emp.id}
								onMouseDown={() => addPin(emp.id)}
							>
								{emp.first_name} {emp.last_name}
								{pinnedIds.includes(emp.id) ? (
									<MdOutlinePushPin className='black' />
								) : (
									<FaPlus className='green' />
								)}
							</p>
						))
					) : (
						<p className='no-res'>No results.</p>
					)}
				</div>
			</div>

			{/* pinned employees */}
			<div className='pinned-container'>
				{pinnedUsers.length ? (
					pinnedUsers.map((emp) => (
						<button
							className={
								emp.location_name ? 'emp online-emp' : ' emp offline-emp'
							}
							key={emp.id}
							onClick={() => navigate(`/user/${emp.id}`)}
						>
							{/* user icon */}
							<FaUser className='user-icon' />
							{/* user details */}
							<div>
								<h4 className='id'>{emp.employee_id}</h4>
								<h3 className='name'>
									{emp.first_name} {emp.last_name}
								</h3>
								{emp.location_name ? (
									<p className='italic'>
										At {emp.location_name} since {formatDate(emp.since, 'time')}
									</p>
								) : (
									<p className='italic'>Offsite</p>
								)}
							</div>
							{/* unpin icon */}
							<RiUnpinLine
								className='un-pin-icon'
								onClick={(event) => unpin(event, emp.id)}
							/>
						</button>
					))
				) : (
					<p>No pinned employees.</p>
				)}
			</div>
		</main>
	);
}

export default Dashboard;
