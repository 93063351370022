import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import {
	formatDate,
	getStatusClassName,
	getTodaysDateTime,
} from '../../utils/helper.js';
import { v4 as uuidv4 } from 'uuid';

function IncidentsAction() {
	const [actionForm, setActionForm] = useState({
		description: '',
		when: '',
		status: '',
	});
	const [actions, setActions] = useState([]);
	const [errorMsg, setErrorMsg] = useState('');
	const [confirmMsg, setConfirmMsg] = useState('');
	const { incidentId } = useParams();
	const navigate = useNavigate();
	const axiosPrivate = useAxiosPrivate();

	useEffect(() => {
		async function fetchData() {
			try {
				const response = await axiosPrivate.get(
					`/incidents/actions/${incidentId}`
				);
				setActions(response.data);
			} catch (error) {
				console.log(error);
			}
		}

		fetchData();
	}, []);

	function handleChange(event) {
		const { name, value } = event.target;
		setActionForm({
			...actionForm,
			[name]: value,
		});
		//clear error
		setErrorMsg('');
	}

	async function handleCreateAction(event) {
		event.preventDefault(); // stop automatic page refresh
		setErrorMsg('');
		setConfirmMsg('');

		if (
			actionForm.description === '' ||
			actionForm.when === '' ||
			actionForm.status === ''
		) {
			setErrorMsg('Cannot leave any field blank.');
			return;
		}

		try {
			// create action
			const body = {
				...actionForm,
				incident_id: incidentId,
				submitted_user: 555,
			};

			await axiosPrivate.post('/incidents/create-action', body);

			//upadate locally
			const localBody = {
				...body,
				id: uuidv4(), // get random id
				first_name: 'Admin',
				last_name: '',
			};
			setActions([localBody, ...actions]);

			// clear form
			setActionForm({
				description: '',
				when: '',
				status: '',
			});

			// feedback message
			setConfirmMsg('Successfully actioned');
		} catch (error) {
			setErrorMsg(error.message);
		}
	}

	return (
		<main className='action-main'>
			{/* title/header */}
			<div className='create-incident-top'>
				<h2>Action Incident</h2>
				<button
					className='btn back-btn'
					onClick={() => navigate('/incidents')}
				>
					<FaArrowLeft />
					Go Back
				</button>
			</div>
			<hr />

			{/* create action */}
			<form
				className='create-action'
				onSubmit={handleCreateAction}
			>
				<label>
					<h4>Description</h4>
					<textarea
						className='message-box'
						rows='5'
						placeholder='Enter description of what was done'
						name='description'
						onChange={handleChange}
						value={actionForm.description}
					></textarea>
				</label>

				<label>
					<h4>Date & time</h4>
					<input
						type='datetime-local'
						name='when'
						max={getTodaysDateTime()}
						onChange={handleChange}
						value={actionForm.when}
					></input>
				</label>

				<label>
					<h4>Status</h4>
					<select
						value={actionForm.status}
						name='status'
						onChange={handleChange}
					>
						<option
							value=''
							disabled
						>
							--select status--
						</option>
						<option>open</option>
						<option>in progress</option>
						<option>closed</option>
					</select>
				</label>

				<button
					className='btn'
					type='submit'
				>
					Submit
				</button>

				{/* feedback messages */}
				<p className='error'>{errorMsg}</p>
				<p className='success'>{confirmMsg}</p>
			</form>

			{/* previous actions */}
			<h3>Action History</h3>
			<hr />
			{actions.length ? (
				actions.map((action) => (
					<div
						className='action-history-container'
						key={action.id}
					>
						<p>
							On <span className='italic'>{formatDate(action.when)}</span>,
							<span className='italic blue'>
								{action.first_name} {action.last_name + ' '}
							</span>
							set status to
							<span className={getStatusClassName(action.status)}>
								{' ' + action.status}
							</span>
							.
						</p>
						<h4>Description:</h4>
						<p>{action.description}</p>
					</div>
				))
			) : (
				<p className='no-logs'>No previous actions</p>
			)}
		</main>
	);
}

export default IncidentsAction;
