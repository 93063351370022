function Missing() {
	return (
		<main>
			<h2>Page Not Found</h2>
			<br />
			<p>Sorry, no page was found.</p>
		</main>
	);
}

export default Missing;
