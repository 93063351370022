export function formatDate(date, type) {
	/*
    convert timestamp to readable
    e.g. 2024-07-10T23:34:25.000Z to 6:30 am or 12/04/24 or 6:30 am, 12/04/24
    type = 'time', 'date' or <empty> for both
    */
	if (date === '' || date == null) {
		return '';
	}

	const optionsTime = {
		hour: '2-digit',
		minute: '2-digit',
		hour12: true,
	};

	const optionsDate = {
		day: '2-digit',
		month: '2-digit',
		year: '2-digit',
	};

	if (type === 'time') {
		return new Date(date).toLocaleTimeString([], optionsTime);
	} else if (type === 'date') {
		return new Date(date).toLocaleDateString([], optionsDate);
	} else {
		return new Date(date).toLocaleString([], {
			...optionsTime,
			...optionsDate,
		});
	}
}

export function getTodaysDateTime() {
	/*
    gets current local date and time and converts it to format YYYY-MM-DDTHH:MM:SS
    one use case is min/max in input type=date html
    */
	const dateTime = new Date().toLocaleString();
	const [date, time] = dateTime.split(', ');
	const [day, month, year] = date.split('/');
	return `${year}-${month}-${day}T${time}`;
}

export function getStatusClassName(status) {
	switch (status) {
		case 'open':
			return 'red';
		case 'closed':
			return 'green';
		case 'in progress':
			return 'amber';
		default:
			return '';
	}
}
