import { useState, useEffect, useContext } from 'react';
import { getLocations, createIncident } from '../../api/urls.js';
import { useNavigate } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';
import { UserContext } from '../../context/userContext.js';
import useAxiosPrivate from '../../hooks/useAxiosPrivate.js';

function IncidentsCreate() {
	const { user } = useContext(UserContext);
	const [formData, setFormData] = useState({
		title: '',
		description: '',
		location: 'N/A',
		dateStart: '',
		dateEnd: '',
	});
	const [isLoading, setIsLoading] = useState(false);
	const [errorMsg, setErrorMsg] = useState('');
	const [confirmationMsg, setConfirmationMsg] = useState('');
	const [locationList, setLocationList] = useState('');
	const navigate = useNavigate();
	const axiosPrivate = useAxiosPrivate();
	const todayDate = new Date().toISOString().split('T')[0];

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axiosPrivate.get(getLocations(user.company_id));
				setLocationList(response.data);
			} catch (error) {
				// handle error
			}
		};

		fetchData();
	}, []);

	function handleChange(event) {
		const { name, value } = event.target;
		setFormData({
			...formData,
			[name]: value,
		});
		//clear feeback
		setErrorMsg('');
		setConfirmationMsg('');
	}

	async function handleSubmit(event) {
		event.preventDefault(); // stop browser page reload
		setIsLoading(true);
		setErrorMsg('');
		setConfirmationMsg('');
		// validate not blank
		if (
			formData.title === '' ||
			formData.description === '' ||
			formData.dateStart === '' ||
			formData.dateEnd === ''
		) {
			setErrorMsg('Cannot leave title, description or date(s) blank.');
			setIsLoading(false);
			return;
		}

		try {
			let body = {
				title: formData.title,
				description: formData.description,
				submitted_user: 555,
				companyID: 1,
				dateStart: formData.dateStart,
				dateEnd: formData.dateEnd,
			};

			if (formData.location !== 'N/A') {
				body = {
					...body,
					location: formData.location,
				};
			}

			await axiosPrivate.post(createIncident, body);
			// clear form
			setFormData({
				title: '',
				description: '',
				location: 'N/A',
				dateStart: '',
				dateEnd: '',
			});
			setConfirmationMsg('Successfully created incident');
		} catch (error) {
			setErrorMsg('Failed to create incident.');
		} finally {
			setIsLoading(false);
		}
	}

	return (
		<main className='create-incident-main'>
			<div className='create-incident-top'>
				<h2>Create Incident</h2>
				<button
					className='btn back-btn'
					onClick={() => navigate('/incidents')}
				>
					<FaArrowLeft />
					Go Back
				</button>
			</div>
			<hr />

			<form onSubmit={handleSubmit}>
				<label>
					<h4>Title</h4>
					<input
						className='create-incident-inpt-text'
						name='title'
						type='text'
						placeholder='Summary of incident'
						onChange={handleChange}
						value={formData.title}
					/>
				</label>

				<label>
					<h4>Description</h4>
					<textarea
						className='create-incident-inpt-text'
						name='description'
						rows='10'
						type='text'
						placeholder='Enter description of incident'
						onChange={handleChange}
						value={formData.description}
					/>
				</label>

				<label>
					<h4>Location</h4>
					<select
						className='location-select'
						value={formData.location}
						name='location'
						onChange={handleChange}
					>
						<option>N/A</option>
						{locationList.length > 0
							? locationList.map((location) => (
									<option key={location.name}>{location.name}</option>
							  ))
							: ''}
					</select>
				</label>

				<label>
					<h4>Date</h4>
					<input
						className='date-select'
						type='date'
						name='dateStart'
						max={
							formData.dateEnd < todayDate || formData.dateEnd === ''
								? todayDate
								: formData.dateEnd
						}
						value={formData.dateStart}
						onChange={handleChange}
					/>
					<p className='to'>to</p>
					<input
						className='date-select'
						type='date'
						name='dateEnd'
						min={formData.dateStart}
						max={todayDate}
						value={formData.dateEnd}
						onChange={handleChange}
					/>
				</label>
				{isLoading ? (
					<button
						type='submit'
						className='btn btn-disabled btn-create-incident'
						disabled='true'
					>
						Submiting...
					</button>
				) : (
					<button
						type='submit'
						className='btn btn-create-incident'
					>
						Submit
					</button>
				)}
			</form>

			{/* feedback messages */}
			<p className='error'>{errorMsg}</p>
			<p className='success'>{confirmationMsg}</p>
		</main>
	);
}

export default IncidentsCreate;
